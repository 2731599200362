.vjs-big-play-button {
    left: 50% !important;
    top: 50% !important;
}
.video-container, .mini-video-player {
    @apply w-full overflow-hidden rounded-custom;

    .video-js {
        @apply w-full;
    }

    .vjs-control-bar {
        @apply bg-grey-11 h-10 py-2 px-6 gap-6
        !important;
    }

    .vjs-play-control,
    .vjs-volume-panel,
    .vjs-mute-control,
    .vjs-fullscreen-control,
    .vjs-picture-in-picture-control {
        @apply w-6 h-6
        !important;
    }

    .vjs-volume-bar {
        @apply my-2.5
        !important;
    }

    .vjs-play-progress:before {
        top: 50%;
        transform: translateY(-50%);
    }

    .vjs-icon-placeholder::before {
        @apply leading-6 text-2xl
        !important;
    }

    .vjs-control.vjs-volume-panel:active,
    .vjs-control.vjs-volume-panel:hover {
        @apply w-16
        !important;
    }

    .vjs-big-play-button {
        display: none !important;
    }

    .duration-text {
        text-shadow: 0 0 1px black
    }

    .vjs-loading-spinner {
        margin: 0 !important;
    }

    .vjs-live-display {
        @apply h-[21px] w-13 py-1 bg-live rounded text-center text-white text-xxs2 leading-none font-bold font-sf uppercase tracking-wide
        !important;
    }

    .vjs-live-display .vjs-control-text {
        @apply relative bg-white w-2 h-2 rounded-full mr-1 inline-block text-transparent
        !important;
    }

    .video-container-toggle {
        @apply opacity-0 transition-opacity;
        transition-duration: .35s;
        transition-timing-function: ease-in-out;
    }

    &:hover .video-container-toggle {
        @apply opacity-100;
    }

    .vjs-progress-control {
        @apply h-6
    }

    &.lite-video-container {

        .vjs-control-bar {
            @apply bg-transparent pb-6 mb-3
            !important;
        }
    }

    .vjs-audio-only-mode {
        @apply h-[64px];
    }

    .vjs-audio-only-mode, &.vjs-audio-only-mode {
        .vjs-poster {
            @apply block
            !important;

            img {
                @apply w-full h-full object-cover
                !important;
            }
        }

        .vjs-tech {
            @apply block
            !important;
        }
    }
}

.mini-video-player.vjs-audio-only-mode {
    @apply h-[240px];
}

.video-card-wrapper {
    video[poster]{
        object-fit: cover !important;
    }
    .vjs-poster img {
        object-fit: cover !important;
    }
}

/*<div className="h-[21px] px-2 py-1 bg-[#d20200] rounded justify-center items-center gap-1 inline-flex">*/
/*<div className="w-2 h-2 bg-white rounded-full" />*/
/*<div className="text-center text-white text-xxs2 font-bold font-['SF Pro Display'] uppercase tracking-wide">Live</div>*/
/*</div>*/


/*https://developer.mozilla.org/en-US/docs/Web/API/Document_Picture-in-Picture_API/Using*/
/*.video-container video:picture-in-picture {*/
/*    !* your styles here *!*/
/*}*/
